import React, { useState } from "react";
import { useRef } from "react";
import { bytesToMegaBytes } from "../../utils/Utils";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { X } from "react-feather";
import {
  createObject,
  deleteSingleObject,
} from "../../app/reducers/MediaLibrary/mediaLibrarySlice";

const FormikDirectMultiFileUpload = ({
  name,
  formik,
  label = "",
  required,
  location,
  id,
  ...props
}) => {
  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const [finalStrings, setFinalStrings] = useState([]);
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col">
      <label
        className="block text-sm font-medium mb-1 underline cursor-pointer"
        htmlFor={id}
      >
        {label} {required && <span className="text-rose-500">*</span>}
      </label>
      <input
        id={id}
        className="hidden"
        ref={ref}
        type="file"
        multiple
        onChange={async (e) => {
          const files = [];
          setLoading(true);
          if (!location) {
            alert("No Location Selected");
            return;
          }
          try {
            for (let i = 0; i < e.currentTarget.files.length; i++) {
              const file = e.currentTarget.files[i];
              console.log(file);
              files.push(file);
            }
            const resp = await dispatch(createObject({ files, location }));
            const keys = resp.payload.data.map((item) => item.key);
            const newStrings = [...finalStrings, ...keys];
            setFinalStrings(newStrings);

            formik.setFieldValue(name, newStrings);
          } catch (error) {
            console.log(error);
            toast.error("Error uploading files");
          } finally {
            setLoading(false);
            ref.current.value = null;
          }
        }}
        {...props}
        onBlur={formik.handleBlur}
      />
      {loading ? (
        <ClipLoader />
      ) : (
        <>
          {finalStrings.map((item, index) => {
            return (
              <div className="flex gap-2">
                <p className="max-w-[300px] truncate" key={index}>
                  {item}
                </p>
                <X
                  color="red"
                  onClick={async () => {
                    await dispatch(
                      deleteSingleObject({
                        keys: finalStrings[index],
                      })
                    );
                    const filteredStrings = finalStrings.filter(
                      (_, i) => i !== index
                    );
                    setFinalStrings(filteredStrings);
                    formik.setFieldValue(name, filteredStrings);
                  }}
                />
              </div>
            );
          })}
        </>
      )}

      {formik.errors[name] && formik.touched[name] ? (
        <p className="text-xs text-red-500">{formik.errors[name]}</p>
      ) : null}
    </div>
  );
};

export default FormikDirectMultiFileUpload;

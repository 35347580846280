import React from "react";
import { Link } from "react-router-dom";
import AuthLogo from "../../images/citroenLogo.png";

const Header = () => {
  return (
    <div className="bg-[#F7F7F7] grid grid-cols-2 justify-center items-center gap-[3rem]">
      <div className="w-[100%] ml-[10%] lg:ml-[20%] my-[20px] lg:w-[30%] flex justify-center">
        <Link to="/home">
          <img src={AuthLogo} />
        </Link>
      </div>
      <div className="text-black flex gap-2 md:gap-8 justify-center items-center ">
        <Link to="/login">
          <p className="uppercase border-black md:font-bold tracking-wide border-r-2 px-[10px] md:border-r-4 md:px-[25px] md:py-[10px]">
            Login
          </p>
        </Link>
        <Link to="/register">
          <p className="uppercase md:font-bold tracking-wide">Sign Up</p>
        </Link>
      </div>
    </div>
  );
};

export default Header;

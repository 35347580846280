import React, { useState, useEffect, useRef } from "react";
import Validator from "simple-react-validator"; /* React Validator */
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate } from "react-router-dom";
import { useForceUpdate } from "../../Common/Function";
import AuthImage from "../../images/loginImage.jpeg";
import AuthLogo from "../../images/citroenLogo.png";
import AuthDecoration from "../../images/auth-decoration.png";
import {
  checkJwt,
  getAuth,
  postLogin,
} from "../../app/reducers/Auth/authSlice";
import { useFormik } from "formik";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import FormikFileInput from "../../components/formik/FormikFileInput";
import { generateOptions } from "../../utils/Utils";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { mixed, object, string } from "yup";
import * as Yup from "yup";
import Header from "../../components/infrastructure/Header";
import {
  CREW_MEMBERS,
  FOOD_PREFERENCE,
  GENDER,
  MUSIC_PREFERENCE,
  TITLE,
  TSHIRT_SIZE,
} from "../../utils/dropdownOptions";
import { axiosInstance } from "../../utils/axiosConfig";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
const computeFrom = (location) => {
  const from = location?.state?.from;
  if (from && from !== "/logout") {
    return from;
  } else {
    return "/";
  }
};
const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const from = computeFrom(location);

  const forceUpdate = useForceUpdate();
  const { loading, jwt, error } = useSelector(getAuth);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState("");
  // const [allValues, setValues] = useState({ phone: "", password: "" });
  const formik = useFormik({
    initialValues: {
      title: "",
      name: "",
      companyName: "",
      designation: "",
      dateOfBirth: null,
      cityOfOrigin: "", //extra field
      musicPreference: "",
      foodPreference: "",
      tShirtSize: "",
      drivingLicense: null,
      username: "",
      password: "",
      confirmPassword: "",
      crewMembers: "0",
    },
    validationSchema: object({
      title: string().required(),
      name: string().required(),
      companyName: string().required(),
      designation: string().required(),
      dateOfBirth: string().required(),
      musicPreference: string(),
      foodPreference: string(),
      tShirtSize: string().required(),
      drivingLicense: mixed().required(),
      username: string().required().email(),
      password: string().required(),
      crewMembers: string().required(),
      cityOfOrigin: Yup.string().required(),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        console.log(values);
        setLoadingSubmit(true);
        const resp = await axiosInstance.post("user/register", values, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(resp);
        setLoadingSubmit(false);
        toast.success("Registration Successful");
        resetForm();
        setErrorSubmit("");
        navigate(`/registration-successful/${resp.data.data.user.username}`);
      } catch (error) {
        console.log(error);
        setLoadingSubmit(false);
        toast.error("Registration Failed");
        setErrorSubmit(error.response.data.message);
      }
    },
  });

  useEffect(() => {
    console.log("========== from in Register js==============");
    console.log(from);
    console.log("========== location in Register js==============");
    console.log(location);
    dispatch(checkJwt());
    if (jwt) {
      navigate(from);
    }
  }, [jwt]);

  return (
    <>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div className="w-full">
          <Header />
          <div className="w-full h-full">
            <div className="flex flex-col-reverse md:grid md:grid-cols-2 md:justify-center gap-[4rem] md:items-center mx-[10%] pt-[2%] ">
              <div className="mt-[2%] flex flex-col items-start justify-center">
                <h1 className="font-bold text-4xl text-[#000]">Sign Up</h1>
                <div className="mt-[20px] w-full">
                  {loadingSubmit ? (
                    <ClipLoader />
                  ) : (
                    <form
                      onSubmit={formik.handleSubmit}
                      className="flex flex-col gap-2"
                    >
                      <p className="text-rose-600">{errorSubmit}</p>
                      <div className="flex flex-col gap-2 md:flex-row md:gap-6">
                        <div className="w-full md:w-[50%]">
                          <FormikSelectGroup
                            name="title"
                            formik={formik}
                            label="Title"
                            required
                            options={generateOptions({
                              array: TITLE,
                              labelField: "label",
                              valueField: "value",
                            })}
                          />
                        </div>
                        <div className="w-full md:w-[50%]">
                          <FormikInputGroup
                            label="Name"
                            required
                            formik={formik}
                            name="name"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 md:flex-row md:gap-6">
                        <div className="w-full md:w-[50%]">
                          <FormikInputGroup
                            label="Company Name"
                            required
                            formik={formik}
                            name="companyName"
                          />
                        </div>
                        <div className="w-full md:w-[50%]">
                          <FormikInputGroup
                            label="Designation"
                            required
                            formik={formik}
                            name="designation"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 md:flex-row md:gap-6">
                        <div className="w-full md:w-[50%]">
                          <FormikInputDateGroup
                            name="dateOfBirth"
                            label="Date Of Birth"
                            required
                            formik={formik}
                          />
                        </div>
                        <div className="w-full md:w-[50%]">
                          <FormikInputGroup
                            name="cityOfOrigin"
                            label="City Of Origin"
                            formik={formik}
                            required
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 md:flex-row md:gap-6">
                        <div className="w-full md:w-[50%]">
                          <FormikSelectGroup
                            name="musicPreference"
                            label="Music Preference"
                            formik={formik}
                            options={generateOptions({
                              array: MUSIC_PREFERENCE,
                              labelField: "label",
                              valueField: "value",
                            })}
                          />
                        </div>
                        <div className="w-full md:w-[50%]">
                          <FormikSelectGroup
                            name="foodPreference"
                            label="Food Preference"
                            formik={formik}
                            options={generateOptions({
                              array: FOOD_PREFERENCE,
                              labelField: "label",
                              valueField: "value",
                            })}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 md:flex-row md:gap-6">
                        <div className="w-full md:w-[50%]">
                          <FormikSelectGroup
                            name="tShirtSize"
                            label="T-Shirt Size"
                            formik={formik}
                            required
                            options={generateOptions({
                              array: TSHIRT_SIZE,
                              labelField: "label",
                              valueField: "value",
                            })}
                          />
                        </div>
                        <div className="w-full md:w-[50%]">
                          <FormikFileInput
                            name="drivingLicense"
                            label="Driving License"
                            required
                            formik={formik}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 md:flex-row md:gap-6">
                        <div className="w-full md:w-[50%]">
                          <FormikInputGroup
                            name="username"
                            formik={formik}
                            required
                            label="Username/Email"
                          />
                        </div>
                        <div className="w-full md:w-[50%]">
                          <FormikInputGroup
                            name="password"
                            formik={formik}
                            required
                            label="Password"
                            type="password"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 md:flex-row md:gap-6">
                        <div className="w-full md:w-[50%]">
                          <FormikInputGroup
                            name="confirmPassword"
                            formik={formik}
                            required
                            label="Confirm Password"
                            type="password"
                          />
                        </div>
                        <div className="w-full md:w-[50%]">
                          <FormikSelectGroup
                            name="crewMembers"
                            label="Crew Members"
                            options={generateOptions({
                              array: CREW_MEMBERS,
                              labelField: "label",
                              valueField: "value",
                            })}
                            formik={formik}
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <PrimaryButton type="submit">Sign Up</PrimaryButton>
                      </div>
                    </form>
                  )}
                </div>
              </div>
              <div className="flex justify-center items-center">
                <img src={AuthImage} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;

import { useFormik } from "formik";
import React, { useRef } from "react";
import { number, object, string } from "yup";
import FormikInputDateGroup from "../components/formik/FormikInputDateGroup";
import FormikInputGroup from "../components/formik/FormikInputGroup";
import FormikSelectGroup from "../components/formik/FormikSelectGroup";
import FormikTextareaGroup from "../components/formik/FormikTextareaGroup";
import PrimaryButton from "../components/infrastructure/Buttons/PrimaryButton";
import {
  fetchProjects,
  getProjects,
} from "../app/reducers/Project/projectSlice";
import { createProject } from "../app/reducers/Submission/submissionSlice";
import { getAuth } from "../app/reducers/Auth/authSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateOptions } from "../utils/Utils";
import { useState } from "react";
import FormikMultiFileInput from "../components/formik/FormikMultiFileInput";
import SignatureCanvas from "react-signature-canvas";
import { AREA_OF_CHECKING } from "../utils/dropdownOptions";
import moment from "moment";
import { X } from "react-feather";
import { useNavigate } from "react-router-dom";
import FormikPhoneInputGroup from "../components/formik/FormikPhoneInputGroup";
import { getSubmission } from "../app/reducers/Submission/submissionSlice";
import { ClipLoader } from "react-spinners";
import FormikDirectMultiFileUpload from "../components/formik/FormikDirectMultiFileUpload";
import PhotoModal from "./PhotoModal";
import FormikEditorInput from "../components/formik/FormikEditorInput";

const BlackInk = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [subProjectOption, setSubProject] = useState([]);
  const [locations, setLocation] = useState(null);
  const { user } = useSelector(getAuth);
  let signPad = useRef();
  let blankSignPad = useRef();
  const { loading } = useSelector(getSubmission);
  const [photoModalOpen, setPhotoModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchProjects({ assignedUsers: { $in: [user._id] } }));
  }, []);

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator?.permissions
        ?.query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition((data) => {
              console.log("done", data);
              setLocation({
                latitude: data?.coords.latitude,
                longitude: data?.coords.longitude,
              });
            });
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition((data) => {
              setLocation({
                latitude: data?.coords.latitude,
                longitude: data?.coords.longitude,
              });
            });
          } else if (result.state === "denied") {
            setLocation(null);
          }
        });
    } else {
      alert("Sorry Not available!");
    }
  }, []);

  const { project, loading: projectLoading } = useSelector(getProjects);
  const clear = () => {
    signPad.clear();
  };

  const clearBlankSign = () => {
    blankSignPad.clear();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user && user?.firstName,
      lastName: user && user?.lastName,
      projectName: "",
      areaOfChecking: "",
      subProject: "",
      checkingRemarks: "",
      rechecking: "",
      recheckingDate: "",
      recheckingRemarks: "",
      inTime: "",
      outTime: "",
      clientFirstName: "",
      clientLastName: "",
      clientEmail: "",
      siteEngineerFirstName: "",
      siteEngineerLastName: "",
      siteEngineerEmail: "",
      siteEngineerNumber: "91",
      contractorFirstName: "",
      contractorLastName: "",
      contractorEmail: "",
      contractorNumber: "91",
      photos: [],
    },
    validationSchema: object({
      firstName: string().required(),
      lastName: string().required(),
      projectName: string().required(),
      subProject: string().required(),
      areaOfChecking: string().required(),
      checkingRemarks: string().required(),
      rechecking: string().required(),
      recheckingDate: string().when("rechecking", {
        is: (rechecking) => rechecking == "no",
        then: string(),
        otherwise: string().required(),
      }),
      recheckingRemarks: string().when("rechecking", {
        is: (rechecking) => rechecking == "no",
        then: string(),
        otherwise: string().required(),
      }),
      inTime: string().required(),
      outTime: string().required(),
      clientFirstName: string().required(),
      clientLastName: string().required(),
      clientEmail: string().email().required(),
      siteEngineerFirstName: string(),
      siteEngineerLastName: string(),
      siteEngineerEmail: string().email(),
      siteEngineerNumber: number(),
      contractorNumber: number(),
      contractorEmail: string(),
      contractorFirstName: string(),
      contractorLastName: string(),
    }),
    onSubmit: async (values, { resetForm }) => {
      // const location = `black_ink/projects/${values.projectName}/${values.subProject}/${values.areaOfChecking}/`;
      console.log(values, "values");
      const data = await dispatch(
        createProject({
          ...values,
          submittedBy: user?._id,
          signature: signPad.getTrimmedCanvas().toDataURL("image/png"),
          blankinkSignature: blankSignPad
            .getTrimmedCanvas()
            .toDataURL("image/png"),
          latitude: locations ? locations.latitude : null,
          longitude: locations ? locations.longitude : null,
        })
      );
      console.log(data, "data");
      console.log(data?.payload?.data, "checking navigation");
      if (data?.payload?.data) {
        resetForm();
        navigate(`/blackink/success/${data?.payload?.data?._id}`);
      }
    },
  });
  return (
    <div className="flex justify-center items-center p-4">
      {/* <PhotoModal
        photoModalOpen={photoModalOpen}
        setPhotoModalOpen={setPhotoModalOpen}
      /> */}
      {loading ? (
        <ClipLoader />
      ) : (
        <form
          className="bg-white p-4 rounded-xl shadow-md flex flex-col gap-4 sm:w-1/2 md:w-3/5 lg:w-1/3 text-xs"
          onSubmit={formik.handleSubmit}
        >
          <h1 className="text-xl font-semi-bold">BlackInk Site Report</h1>

          <div className="flex flex-col gap-4 border-y-2 p-2">
            <p className="text-md">Your Name:</p>
            <div className="flex gap-2">
              <FormikInputGroup
                name="firstName"
                formik={formik}
                label="First Name"
                required
              />
              <FormikInputGroup
                name="lastName"
                formik={formik}
                label="Last Name"
                required
              />
            </div>
          </div>
          <FormikSelectGroup
            name="projectName"
            formik={formik}
            onChange={(data) => {
              let findProject = project.docs.find((d) => d.name == data.value);
              if (findProject) {
                let subProjectOptions = findProject.subProject?.map((d) => {
                  return { label: d, value: d };
                });
                formik.setFieldValue("projectName", data.value);
                formik.setFieldValue(
                  "clientFirstName",
                  findProject.client_firstName
                );
                formik.setFieldValue(
                  "clientLastName",
                  findProject.client_lastName
                );
                formik.setFieldValue("clientEmail", findProject.client_email);
                setSubProject(subProjectOptions);
              }
            }}
            label="Project Name"
            options={generateOptions({
              array: project?.docs ? project.docs : [],
              valueField: "name",
              labelField: "name",
            })}
            required
          />
          <FormikSelectGroup
            name="subProject"
            formik={formik}
            label="Tower/Wing Name"
            options={subProjectOption}
            required
          />
          <FormikSelectGroup
            name="areaOfChecking"
            formik={formik}
            label="Area of checking"
            options={generateOptions({
              array: AREA_OF_CHECKING,
              labelField: "label",
              valueField: "value",
            })}
            required
          />
          {/* <FormikTextareaGroup
            name="checkingRemarks"
            formik={formik}
            label="Checking Remarks"
          /> */}
          <FormikEditorInput
            name="checkingRemarks"
            formik={formik}
            label="Checking Remarks"
          />
          <FormikSelectGroup
            name="rechecking"
            label="Rechecking"
            options={[
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ]}
            formik={formik}
            required
          />
          {formik?.values?.rechecking === "yes" && (
            <>
              <FormikInputDateGroup
                name="recheckingDate"
                label="Re-Checking Date"
                formik={formik}
              />
              <FormikTextareaGroup
                name="recheckingRemarks"
                formik={formik}
                label="Re-Checking Remarks"
              />
            </>
          )}
          <div className="flex justify-between">
            <FormikInputDateGroup
              label="In Time"
              name="inTime"
              formik={formik}
              onChange={(selectedDate) => {
                let hours = new Date(selectedDate).getHours();
                let min = new Date(selectedDate).getMinutes();
                selectedDate.setHours(hours, min, 0, 0);
                formik.setFieldValue(
                  "inTime",
                  new Date(selectedDate).getTime()
                );
              }}
              showTimeSelectOnly
              showTimeSelect
              timeFormat="HH:mm"
              minTime={new Date().setHours(0, 0, 0)}
              maxTime={new Date().setHours(23, 59, 59)}
              timeIntervals={15}
              timeCaption="time"
              dateFormat="h:mm aa"
            />
            <FormikInputDateGroup
              label="Out Time"
              name="outTime"
              formik={formik}
              onChange={(selectedDate) => {
                let hours = new Date(selectedDate).getHours();
                let min = new Date(selectedDate).getMinutes();
                selectedDate.setHours(hours, min, 0, 0);
                formik.setFieldValue(
                  "outTime",
                  new Date(selectedDate).getTime()
                );
              }}
              showTimeSelectOnly
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              minTime={new Date().setHours(0, 0, 0)}
              maxTime={new Date().setHours(23, 59, 59)}
              timeCaption="time"
              dateFormat="h:mm aa"
            />
          </div>
          {/* <div className="flex flex-col gap-4 border-y-2 p-2">
          <p className="text-md">Client Details</p>
          <div className="flex gap-2">
            <FormikInputGroup
              name="clientFirstName"
              formik={formik}
              label="Client First Name"
              required
            />
            <FormikInputGroup
              name="clientLastName"
              formik={formik}
              label="Client Last Name"
              required
            />
          </div>
          <FormikInputGroup
            name="clientEmail"
            label="Client Email"
            required
            formik={formik}
          />
        </div> */}
          <div className="flex flex-col gap-4 border-y-2 p-2">
            <p className="text-md">Site Engineer Details</p>
            <div className="flex gap-2">
              <FormikInputGroup
                name="siteEngineerFirstName"
                formik={formik}
                label="Site Engineer First Name"
              />
              <FormikInputGroup
                name="siteEngineerLastName"
                formik={formik}
                label="Site Engineer Last Name"
              />
            </div>
            <FormikInputGroup
              name="siteEngineerEmail"
              label="Site Engineer Email"
              formik={formik}
            />
            <FormikPhoneInputGroup
              name="siteEngineerNumber"
              label="Site Engineer Number"
              required
              formik={formik}
            />
          </div>
          <div className="flex flex-col gap-4 border-y-2 p-2">
            <p className="text-md">Contractor Engineer Details</p>
            <div className="flex gap-2">
              <FormikInputGroup
                name="contractorFirstName"
                formik={formik}
                label="First Name"
                required
              />
              <FormikInputGroup
                name="contractorLastName"
                formik={formik}
                label="Last Name"
                required
              />
            </div>
            <FormikInputGroup
              name="contractorEmail"
              label="Contractor Engineer Email"
              formik={formik}
            />
            <FormikPhoneInputGroup
              name="contractorNumber"
              label="Contractor Engineer Number"
              required
              formik={formik}
            />
          </div>
          {formik.values.projectName &&
            formik.values.subProject &&
            formik.values.areaOfChecking && (
              <FormikDirectMultiFileUpload
                name="photos"
                formik={formik}
                label="Click to upload photos"
                required
                location={`black_ink/projects/${formik.values.projectName}/${formik.values.subProject}/${formik.values.areaOfChecking}/`}
                id="blackink_multi_photo"
              />
              // <PrimaryButton
              //   onClick={(e) => {
              //     e.stopPropagation();
              //     setPhotoModalOpen(true);
              //   }}
              // >
              //   Upload Photos
              // </PrimaryButton>
            )}
          <div>
            <h3 className="block uppercase font-thin mb-2">
              Site Engineer Signature
            </h3>
            <div
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                position: "relative",
              }}
            >
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: "auto",
                  height: 150,
                  className: "sigCanvas",
                }}
                ref={(ref) => {
                  signPad = ref;
                }}
              />
              <X
                style={{ position: "absolute", top: "3px", right: "0px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  clear();
                }}
              >
                Clear
              </X>
            </div>
          </div>
          <div>
            <h3 className="block uppercase font-thin mb-2">
              BlackInk Engineer Signature
            </h3>
            <div
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                position: "relative",
              }}
            >
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: "auto",
                  height: 150,
                  className: "sigCanvas",
                }}
                ref={(ref) => {
                  blankSignPad = ref;
                }}
              />
              <X
                style={{ position: "absolute", top: "3px", right: "0px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  clearBlankSign();
                }}
              >
                Clear
              </X>
            </div>
          </div>
          <div>
            <PrimaryButton>Submit</PrimaryButton>
          </div>
        </form>
      )}
    </div>
  );
};

export default BlackInk;

import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  createWithFile,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";

const SLICE_NAME = "submission";

export const createProject = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchSubmission = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);

const submissionReducer = createSlice({
  initialState: generateStateSingle(SLICE_NAME),
  name: SLICE_NAME,
  extraReducers: {
    ...createWithFile.generateExtraReducers(createProject, SLICE_NAME),
    ...fetchData.generateExtraReducers(fetchSubmission, SLICE_NAME),
  },
});

export const getSubmission = (state) => state.submission;

export default submissionReducer.reducer;

import React, { useState, useEffect, useRef } from "react";
import Validator from "simple-react-validator"; /* React Validator */
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForceUpdate } from "../../Common/Function";
import AuthImage from "../../images/loginImage.jpeg";
import AuthLogo from "../../images/citroenLogo.png";
import AuthDecoration from "../../images/auth-decoration.png";
import {
  checkJwt,
  getAuth,
  postLogin,
} from "../../app/reducers/Auth/authSlice";
import { useFormik } from "formik";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { object, string } from "yup";
import Header from "../../components/infrastructure/Header";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
const computeFrom = (location) => {
  const from = location?.state?.from;
  if (from && from !== "/logout") {
    return from;
  } else {
    return "/";
  }
};
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const from = computeFrom(location);

  const forceUpdate = useForceUpdate();
  const { loading, jwt, error } = useSelector(getAuth);
  // const [allValues, setValues] = useState({ phone: "", password: "" });
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: object({
      username: string().required(),
      password: string().required(),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      dispatch(postLogin(values)).then((res) => {
        if (res.payload.success) {
          navigate(from, { replace: true });
          resetForm();
        }
      });
    },
  });

  useEffect(() => {
    console.log("========== from in Login js==============");
    console.log(from);
    console.log("========== location in Login js==============");
    console.log(location);
    dispatch(checkJwt());
    if (jwt) {
      navigate(from);
    }
  }, [jwt]);

  return (
    <>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div className="w-full">
          {/* <Header /> */}
          <div className="w-full h-screen">
            <div className="flex h-full flex-col-reverse justify-center items-center gap-[4rem] md:flex-row mx-[10%]">
              <div className="md:w-[50%] w-full flex flex-col">
                <div>
                  <h1 className="font-bold text-4xl text-[#000]">
                    BlackInk Forms
                  </h1>
                </div>
                <div className="mt-[5%]">
                  <form
                    onSubmit={formik.handleSubmit}
                    className=" flex flex-col gap-6"
                  >
                    <div>
                      <p className="text-rose-600 mb-2">{error}</p>

                      <FormikInputGroup
                        label="Employee Id"
                        required
                        formik={formik}
                        name="username"
                      />
                    </div>
                    <div>
                      <FormikInputGroup
                        label="Password"
                        required
                        formik={formik}
                        name="password"
                        type="password"
                      />
                      {/* <Link to="/forgot-password">
                        <p className="font-thin text-black underline mt-4 cursor-pointer">
                          Forgot Password
                        </p>
                      </Link> */}
                    </div>
                    <div className="mt-4">
                      <PrimaryButton type="submit">Login</PrimaryButton>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;

import React from "react";
import PageWithCard from "../components/infrastructure/PageWithCard";

const PageNotFound = () => {
  return (
    <div className="flex justify-center items-center">
      <h1>Page not found</h1>
    </div>
  );
};

export default PageNotFound;

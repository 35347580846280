import React, { useState, useEffect, useRef } from "react";
import Validator from "simple-react-validator"; /* React Validator */
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate } from "react-router-dom";
import { useForceUpdate } from "../../Common/Function";
import AuthImage from "../../images/loginImage.jpeg";
import AuthLogo from "../../images/citroenLogo.png";
import AuthDecoration from "../../images/auth-decoration.png";
import {
  checkJwt,
  getAuth,
  postLogin,
} from "../../app/reducers/Auth/authSlice";
import { useFormik } from "formik";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { object, string } from "yup";
import Header from "../../components/infrastructure/Header";
import { axiosInstance } from "../../utils/axiosConfig";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
const computeFrom = (location) => {
  const from = location?.state?.from;
  if (from && from !== "/logout") {
    return from;
  } else {
    return "/";
  }
};
const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const from = computeFrom(location);

  const forceUpdate = useForceUpdate();
  const { loading, jwt } = useSelector(getAuth);
  const [formLoading, setFormLoading] = useState(false);
  const [error, setError] = useState("");
  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: object({
      username: string().required().email(),
    }),
    onSubmit: (values, { resetForm }) => {
      setFormLoading(true);
      const resp = axiosInstance
        .post("user/forgot-password", values)
        .then((res) => {
          setFormLoading(false);
          toast.success("password reset email Sent");
          setError("");
          resetForm();
        })
        .catch((error) => {
          setFormLoading(false);
          console.log(error);
          setError(error.response.data.message);
          toast.error("failed to update password");
        });
    },
  });

  useEffect(() => {
    console.log("========== from in ForgotPassword js==============");
    console.log(from);
    console.log("========== location in Login js==============");
    console.log(location);
    dispatch(checkJwt());
    if (jwt) {
      navigate(from);
    }
  }, [jwt]);

  return (
    <>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div className="w-full">
          <Header />
          <div className="w-full h-screen">
            <div className="flex flex-col-reverse justify-center items-center gap-[4rem] md:flex-row mx-[10%] pt-[5%]">
              {formLoading ? (
                <ClipLoader />
              ) : (
                <div className="md:w-[50%] w-full flex flex-col">
                  <div>
                    <h1 className="font-bold text-4xl text-[#000]">
                      ForgotPassword
                    </h1>
                  </div>
                  <div className="mt-[5%]">
                    <form
                      onSubmit={formik.handleSubmit}
                      className=" flex flex-col gap-6"
                    >
                      <div>
                        <p className="text-rose-600 mb-2">{error}</p>

                        <FormikInputGroup
                          label="Username/Email"
                          required
                          formik={formik}
                          name="username"
                        />
                      </div>

                      <div className="mt-4">
                        <PrimaryButton type="submit">
                          Send Password
                        </PrimaryButton>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              <div className="md:w-[50%] flex justify-start items-center">
                <img src={AuthImage} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;

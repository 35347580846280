import React, { useState } from "react";
import { useEffect } from "react";
import libmoji from "libmoji";
import {
  OUTFIT_OPTIONS,
  TRAITS_OPTIONS,
  STYLE,
  SCALE,
  ROTATION,
} from "./constants";
import axios from "axios";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { useSelector } from "react-redux";
import { getAuth } from "../../app/reducers/Auth/authSlice";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";

function parse(event) {
  try {
    return JSON.parse(event.data);
  } catch (error) {
    return null;
  }
}

const CreateAvatar = () => {
  const allGenders = libmoji.genders;
  const allPoses = libmoji.poses;
  const [showIframe, setShowIframe] = useState(true);
  const [showReloadInfo, setShowReloadInfo] = useState(false);
  const { user } = useSelector(getAuth);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      const frame = document.getElementById("frame");
      const json = parse(event);

      if (json?.source !== "readyplayerme") {
        return;
      }

      if (json.eventName === "v1.frame.ready") {
        frame.contentWindow.postMessage(
          JSON.stringify({
            target: "readyplayerme",
            type: "subscribe",
            eventName: "v1.**",
          }),
          "*"
        );
      }

      if (json.eventName === "v1.avatar.exported") {
        setShowIframe(false);
        console.log(`Avatar URL: ${json.data.url}`);
        authAxiosInstance
          .patch("/user/update", {
            _id: user._id,
            modelUrl: json.data.url,
          })
          .then((resp) => {
            console.log(resp.data);
            setShowReloadInfo(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }, []);

  return (
    <div className="w-full h-screen">
      {showIframe ? (
        <iframe
          id="frame"
          src="https://citron.readyplayer.me/avatar?frameApi"
          className="w-full h-full"
        ></iframe>
      ) : (
        // <iframe
        //   src="https://gltf-viewer.donmccurdy.com/"
        //   className="w-full h-full"
        // ></iframe>
        <div className="w-full h-full flex flex-col gap-4 justify-center items-center">
          <h1 className="text-2xl">Thank You for Creating a Avatar</h1>
          {showReloadInfo && (
            <PrimaryButton
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload To View Avatar
            </PrimaryButton>
          )}
        </div>
      )}
    </div>
  );
};

export default CreateAvatar;

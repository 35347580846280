import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "./components/infrastructure/Layout";
import PersistLogin from "./components/infrastructure/PersistLogin";

import "./css/style.scss";
import Login from "./pages/Auth/Login";
import PageNotFound from "./pages/PageNotFound";
import Logout from "./pages/Auth/Logout";

import RouteWithHeaderAndSidebar from "./components/infrastructure/RouteWithHeaderAndSidebar";
import "react-datepicker/dist/react-datepicker.css";
import Register from "./pages/Auth/Register";
import Home from "./pages/Auth/Home";
import SignUpSuccessful from "./pages/Auth/SignUpSuccessful";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import CreateAvatar from "./pages/Avatar/CreateAvatar";
import CreateAvatar2 from "./pages/Avatar/CreateAvatar2";
import BlackInk from "./pages/BlackInk";
import Leave from "./pages/Leave";
import BlackInkSuccess from "./pages/BlackInkSuccess";
import { LeaveStatus } from "./pages/LeaveStatus";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route element={<PersistLogin />}>
            <Route path="*" element={<PageNotFound />} />
            <Route path="/" element={<RouteWithHeaderAndSidebar />}>
              <Route exact path="/" element={<CreateAvatar2 />} />
            </Route>
            <Route exact path="/blackink" element={<BlackInk />} />
            <Route
              exact
              path="/blackink/success/:id"
              element={<BlackInkSuccess />}
            />
            <Route exact path="/leave" element={<Leave />} />
            <Route exact path="/leaveStatus" element={<LeaveStatus />} />
          </Route>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/home" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/registration-successful">
            <Route index element={<Home />} />
            <Route path=":email" element={<SignUpSuccessful />} />
          </Route>

          <Route exact path="/logout" element={<Logout />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

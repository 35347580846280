import React from "react";
import DatePicker from "react-datepicker";
import _ from "lodash";
import moment from "moment";
const FormikInputDateGroup = ({
  formik,
  label = "",
  required = false,
  name,
  ...props
}) => {
  // console.log(moment(_.at(formik.values, name)[0]).valueOf());

  const onChange = props?.onChange
    ? props.onChange
    : (selectedDate) => {
        formik.setFieldValue(name, new Date(selectedDate).getTime());
      };
  const dateFormat = props?.dateFormat ? props.dateFormat : "dd/MM/yyyy";

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="w-full"
    >
      <label className="block uppercase font-thin mb-2" htmlFor={name}>
        {label} {required && <span className="text-black">*</span>}
      </label>
      <DatePicker
        name={name}
        selected={moment(_.at(formik.values, name)[0]).valueOf()}
        onBlur={formik.handleBlur}
        onChange={onChange}
        {...props}
        className="border-2 border-[#5b5754] rounded-lg w-[100%]"
        dateFormat={dateFormat}
        autoComplete="off"
        dropdownMode="select"
      />
      {formik?.errors?.[name] && formik?.touched?.[name] ? (
        <p className="text-xs text-red-500">{formik.errors?.[name]}</p>
      ) : null}
    </div>
  );
};

export default FormikInputDateGroup;

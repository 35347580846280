import { useFormik } from "formik";
import React, { useRef } from "react";
import { number, object, string } from "yup";
import FormikInputDateGroup from "../components/formik/FormikInputDateGroup";
import FormikInputGroup from "../components/formik/FormikInputGroup";
import FormikSelectGroup from "../components/formik/FormikSelectGroup";
import FormikTextareaGroup from "../components/formik/FormikTextareaGroup";
import PrimaryButton from "../components/infrastructure/Buttons/PrimaryButton";
import { createLeave } from "../app/reducers/Leave/leaveSlice";
import { getAuth } from "../app/reducers/Auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { generateOptions } from "../utils/Utils";
import SignatureCanvas from "react-signature-canvas";
import { X } from "react-feather";
import { useNavigate } from "react-router-dom";
import FormikMultiDateSelect from "../components/formik/FormikMultiDateSelect";
import { TYPE_OF_LEAVES, HALF_DAY_TYPE } from "../utils/dropdownOptions";
import moment from "moment";
const Leave = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(getAuth);
  let signPad = useRef();

  const clear = () => {
    signPad.clear();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user && user?.firstName,
      lastName: user && user?.lastName,
      designation: user && user?.designation,
      employeeId: user && user?.employeeId,
      typeOfLeave: "",
      halfDayDate: "",
      leaveReason: "",
      contactPerson: "",
      halfDayType: "",
      NoOfDays: 0,
      selectedDates: [],
    },
    validationSchema: object({
      firstName: string().required(),
      lastName: string().required(),
      typeOfLeave: string().required(),
      leaveReason: string().required(),
      contactPerson: number().required(),
      halfDayType: string(),
    }),
    onSubmit: async (values, { resetForm }) => {
      dispatch(
        createLeave({
          ...values,
          NoOfDays:
            values.selectedDates.length + (values.halfDayDate ? 0.5 : 0),
          submittedBy: user?._id,
          applicantSignature: signPad.getTrimmedCanvas().toDataURL("image/png"),
        })
      );
      clear();
      resetForm();
    },
  });

  return (
    <div className="flex justify-center items-center p-4">
      <form
        className="bg-white p-4 rounded-xl shadow-md flex flex-col gap-4 sm:w-1/2 md:w-3/5 lg:w-1/3 text-xs"
        onSubmit={formik.handleSubmit}
      >
        <h1 className="text-xl font-semi-bold">Leave Form</h1>

        <div className="flex flex-col gap-4 border-y-2 p-2">
          <p className="text-md">Your Name:</p>
          <div className="flex gap-2">
            <FormikInputGroup
              name="firstName"
              formik={formik}
              label="First Name"
              required
            />
            <FormikInputGroup
              name="lastName"
              formik={formik}
              label="Last Name"
              required
            />
          </div>
        </div>
        <FormikSelectGroup
          name="typeOfLeave"
          label="Type Of Leave"
          options={generateOptions({
            array: TYPE_OF_LEAVES,
            labelField: "label",
            valueField: "value",
          })}
          formik={formik}
          required
        />
        <FormikMultiDateSelect
          name="selectedDates"
          label="Full Leave Dates"
          formik={formik}
          mapDays={({ date }) => {
            console.log(date);
            let isWeekend = [0].includes(date.weekDay.index);
            console.log(formik.values.halfDayDate);
            console.log(
              moment(date.unix * 1000)
                .startOf("day")
                .format("DD-MM-YYYY")
            );
            console.log(
              moment(date.unix * 1000)
                .startOf("day")
                .valueOf()
            );
            let isSelected =
              formik.values.halfDayDate.toString() ===
              moment(date.unix * 1000)
                .startOf("day")
                .valueOf()
                .toString();

            if (isWeekend)
              return {
                disabled: true,
                style: { color: "#ccc" },
                onClick: () => alert("Is Weekend"),
              };
            if (isSelected)
              return {
                disabled: true,
                style: { color: "#ccc" },
                onClick: () => alert("Date already selected as half day"),
              };
          }}
        />
        <FormikTextareaGroup
          formik={formik}
          name="leaveReason"
          label="Reason For Leave"
          required
        />

        <FormikSelectGroup
          name="halfDay"
          label="Half Day"
          onChange={(day) => {
            if (day.value === "yes") {
              formik.setFieldValue("halfDay", day.value);
            } else {
              formik.setFieldValue("halfDayDate", "");
              formik.setFieldValue("halfDayType", "");
              formik.setFieldValue("halfDay", day.value);
            }
          }}
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
          formik={formik}
          required
        />
        {formik?.values?.halfDay === "yes" && (
          <>
            <FormikSelectGroup
              name="halfDayType"
              label="Half Day Type"
              options={generateOptions({
                array: HALF_DAY_TYPE,
                labelField: "label",
                valueField: "value",
              })}
              formik={formik}
              required
            />
            <FormikInputDateGroup
              name="halfDayDate"
              label="Select Half Day"
              formik={formik}
              excludeDates={formik.values.selectedDates}
              filterDate={(date) => {
                console.log(date);
                const day = moment(date).day();
                return day !== 0;
              }}
            />
          </>
        )}
        <FormikInputGroup
          formik={formik}
          name="contactPerson"
          type="number"
          label="Person To Contact ( Incase Emergency )"
          required
        />
        <FormikInputGroup
          formik={formik}
          name="NoOfDays"
          label="Number of Days Applied"
          value={
            formik.values.selectedDates.length +
            (formik.values.halfDayDate ? 0.5 : 0)
          }
          readOnly
          required
        />
        <div>
          <h3 className="block uppercase font-thin mb-2">Employee Signature</h3>
          <div
            style={{
              border: "1px solid black",
              borderRadius: "5px",
              position: "relative",
            }}
          >
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: "auto",
                height: 150,
                className: "sigCanvas",
              }}
              ref={(ref) => {
                signPad = ref;
              }}
            />
            <X
              style={{ position: "absolute", top: "3px", right: "0px" }}
              onClick={(e) => {
                e.stopPropagation();
                clear();
              }}
            >
              Clear
            </X>
          </div>
        </div>
        <div>
          <PrimaryButton>Submit</PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default Leave;

import React, { useState, useEffect, useRef } from "react";
import Validator from "simple-react-validator"; /* React Validator */
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForceUpdate } from "../../Common/Function";
import AuthImage from "../../images/loginImage.jpeg";
import AuthLogo from "../../images/citroenLogo.png";
import AuthDecoration from "../../images/auth-decoration.png";
import {
  checkJwt,
  getAuth,
  postLogin,
} from "../../app/reducers/Auth/authSlice";
import { useFormik } from "formik";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { object, string } from "yup";
import Header from "../../components/infrastructure/Header";
const computeFrom = (location) => {
  const from = location?.state?.from;
  if (from && from !== "/logout") {
    return from;
  } else {
    return "/";
  }
};
const SignUpSuccessful = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const from = computeFrom(location);

  const forceUpdate = useForceUpdate();
  const { loading, jwt, error } = useSelector(getAuth);
  // const [allValues, setValues] = useState({ phone: "", password: "" });

  useEffect(() => {
    console.log("========== from in SignUpSuccessful js==============");
    console.log(from);
    console.log("========== location in Home js==============");
    console.log(location);
    dispatch(checkJwt());
    if (jwt) {
      navigate(from);
    }
  }, [jwt]);

  return (
    <>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div className="w-full">
          <Header />
          <div className="w-full h-screen">
            <div className="flex flex-col-reverse justify-center items-center gap-[4rem] md:flex-row mx-[10%] pt-[5%]">
              <div className="md:w-[50%] w-full flex flex-col">
                <div className="flex flex-col justify-center  md:items-start md:justify-start">
                  <h1 className="text-2xl md:text-4xl font-bold text-[#000]">
                    Registration Successful
                  </h1>
                  <p className="mt-[5%]  font-medium font-sans">
                    Thank you for registering for New Citroen ëC3 electric media
                    drive. You shall receive your confirmation mail shortly
                  </p>
                  <div className="mt-[5%] flex gap-8 items-center">
                    <Link to="/login">
                      <PrimaryButton>Login</PrimaryButton>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="md:w-[50%] flex justify-start items-center">
                <img src={AuthImage} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUpSuccessful;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, getAuth } from "../../app/reducers/Auth/authSlice";

const Logout = () => {
  const { loading } = useSelector(getAuth);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    console.log("logging out");
    dispatch(logout());
    navigate("/login");
  }, []);
  return <>{loading ? <h1>loading</h1> : <h1>Logging out</h1>}</>;
};

export default Logout;

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/Auth/authSlice";
import projectReducer from "./reducers/Project/projectSlice";
import leaveReducer from "./reducers/Leave/leaveSlice";
import submissionSlice from "./reducers/Submission/submissionSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    projects: projectReducer,
    leaves: leaveReducer,
    submission: submissionSlice,
  },
});

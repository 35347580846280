import { useFormik } from "formik";
import React, { useRef } from "react";
import {
  fetchProjects,
  getProjects,
} from "../app/reducers/Project/projectSlice";
import { useEffect } from "react";
import PrimaryButton from "../components/infrastructure/Buttons/PrimaryButton";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSubmission } from "../app/reducers/Submission/submissionSlice";
import { useDispatch } from "react-redux";
import QueryString from "qs";
import { saveAs } from "file-saver";
import { axiosInstance } from "../utils/axiosConfig";

const BlackInkSuccess = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  return (
    <div className="flex justify-center items-center p-4">
      <div
        className="bg-white p-4 rounded-xl shadow-md flex flex-col gap-4 sm:w-1/2 md:w-3/5 lg:w-1/3 text-xs"
        // onSubmit={formik.handleSubmit}
      >
        <h1 className="text-xl font-semi-bold">Thank you for your response</h1>
        <div className="border-y-2 pt-5 pb-3">
          <p>Your response has been recorded.</p>
          <PrimaryButton
            onClick={async (e) => {
              try {
                const string = QueryString.stringify({ id: id });
                const response = await axiosInstance.get(
                  `/submission/pdf?${string}`,
                  { responseType: "blob" }
                );
                saveAs(response.data);
              } catch (error) {
                console.log(error);
              }
            }}
            className="mt-2 mb-3"
          >
            Download Pdf
          </PrimaryButton>
          <p style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
            {"<- Back"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlackInkSuccess;

import React, { useState } from "react";
import { Outlet } from "react-router-dom";

const RouteWithHeaderAndSidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
    <div className="flex h-screen overflow-hidden">
      {" "}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main className="relative">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default RouteWithHeaderAndSidebar;

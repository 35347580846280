import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";

const SLICE_NAME = "leave";

export const createLeave = createData.generateThunk(SLICE_NAME,SLICE_NAME);
export const fetchSubmission = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);


const leaveReducer = createSlice({
  initialState: generateStateSingle(SLICE_NAME),
  name: SLICE_NAME,
  extraReducers: {
    ...createData.generateExtraReducers(createLeave, SLICE_NAME),
    ...fetchData.generateExtraReducers(fetchSubmission, SLICE_NAME),
  },
});

export const getLeaves = (state) => state.leaves;

export default leaveReducer.reducer;

import React from "react";
import { useEffect } from "react";
import libmoji from "libmoji";
import { STYLE } from "./constants";
import { Link } from "react-router-dom";

const CreateAvatar2 = () => {
  //Pwa
  const handleInstallClick = () => {
    try {
      const promptEvent = window.deferredPrompt;

      if (promptEvent) {
        promptEvent.prompt();

        promptEvent.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the install prompt");
          } else {
            console.log("User dismissed the install prompt");
          }
        });

        window.deferredPrompt = null;
      } else {
        alert("Pwa not supported on your browser");
      }
    } catch (error) {
      console.log(error);
      if (error) {
        alert("Not Working");
      }
    }
  };

  window.addEventListener("beforeinstallprompt", (event) => {
    event.preventDefault();
    window.deferredPrompt = event;
  });
  return (
    <>
      <button
        className="border p-2 cursor-pointer"
        onClick={handleInstallClick}
      >
        Install App
      </button>
      <div className="flex min-h-screen justify-center flex-col items-center gap-4">
        <Link to="/blackink">
          <p className="text-2xl underline cursor-pointer">Fill Site Form</p>
        </Link>
        <Link to="/leave">
          <p className="text-2xl underline cursor-pointer">Fill Leave Form</p>
        </Link>
        <Link to="/leaveStatus">
          <p className="text-2xl underline cursor-pointer">Leave Status</p>
        </Link>
        <Link to="/logout">
          <p className="text-xl underline cursor-pointer">Logout</p>
        </Link>
      </div>
    </>
  );
};

export default CreateAvatar2;

import React, { useEffect, useMemo } from "react";
import PageWithCard from "../components/infrastructure/PageWithCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubmission, getLeaves } from "../app/reducers/Leave/leaveSlice";
import TableWithHeadingAndSearch from "../components/Table/TableWithHeadingAndSearch";
import { getAuth } from "../app/reducers/Auth/authSlice";
import moment from "moment";
import PaginationClassic from "../components/pagination/PaginationClassic";

export const LeaveStatus = () => {
  const { leave } = useSelector(getLeaves);
  const { user } = useSelector(getAuth);
  const [page, setPage] = React.useState(1);
  const dispatch = useDispatch();

  console.log(leave);

  useEffect(() => {
    dispatch(fetchSubmission({ employeeId: user.employeeId }));
  }, [page]);

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + leave?.pagingCounter;
      },
    },
    {
      Header: "Date",
      Cell: ({ row }) => {
        return moment(row?.original?.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      Header: "Full Day",
      Cell: ({ row }) => {
        return row?.original?.selectedDates?.map((el, i) => (
          <div className="flex flex-col gap-2" key={i}>
            {moment(el).format("DD/MM/YYYY")}
          </div>
        ));
      },
    },
    {
      Header: "Half Day",
      Cell: ({ row }) => {
        const halfDay = row?.original?.halfDayDate
          ? moment(row?.original?.halfDayDate).format("DD/MM/YYYY")
          : null;
        const halfDayType = row?.original?.halfDayType
          ? row?.original?.halfDayType
          : "";
        return (
          <div className="flex flex-col gap-2">
            <p>{halfDay}</p>
            <p>{halfDayType}</p>
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Leave Reason",
      Cell: ({ row }) => {
        return <p>{row?.original?.leaveReason}</p>;
      },
      // accessor: "leaveReason",
    },
    {
      Header: "Type Of Leave",
      accessor: "typeOfLeave",
    },
  ];

  const dataMemo = useMemo(
    () => (leave?.docs ? leave?.docs : []),
    [leave?.docs]
  );
  const columnsMemo = useMemo(() => columns, [columns]);
  return (
    <PageWithCard heading="Leave Status">
      <TableWithHeadingAndSearch data={dataMemo} columns={columnsMemo} />
      <PaginationClassic
        paginationDetails={{
          ...leave,
          docs: leave.docs,
        }}
        setPage={setPage}
      />
    </PageWithCard>
  );
};
